<template>
	<div class="home_view">
		<el-container>
			<el-header>
				象目派后台管理系统<el-button type="danger" size="small" @click="exit">退出登录</el-button>
			</el-header>
			<el-container>
				<el-aside width="200px">
					<el-menu class="el-menu-vertical-demo" :unique-opened="true" :default-active="this.$store.state.defaultid">
						<el-sub-menu v-for="(item,index) in menuList" :index="item.index">
							<template #title>
								<span>{{item.name}}</span>
							</template>
							<el-menu-item v-for="(item2,index2) in item.children" :index="item2.index"
								@click="menulistClick(item2.path,item2.index)">{{item2.name}}</el-menu-item>
						</el-sub-menu>
					</el-menu>
				</el-aside>
				<el-main>
					<router-view />
				</el-main>
			</el-container>
		</el-container>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				menuList: [
					{
						index: '1',
						name: '学科管理',
						children: [
							{
								index: '1-1',
								name: '学科列表',
								path: '/home/subjectlist'
							}
						]
					},
					{
						index: '2',
						name: '年级管理',
						children: [
							{
								index: '2-1',
								name: '年级列表',
								path: '/home/gradeslist'
							}
						]
					},
					{
						index: '3',
						name: '学期管理',
						children: [
							{
								index: '3-1',
								name: '学期列表',
								path: '/home/semesterslist'
							}
						]
					},
					{
						index: '4',
						name: '出版社管理',
						children: [
							{
								index: '4-1',
								name: '出版社列表',
								path: '/home/presseslist'
							}
						]
					},
					{
						index: '5',
						name: '单元管理',
						children: [
							{
								index: '5-1',
								name: '单元列表',
								path: '/home/tmsunitlist'
							}
						]
					},
					{
						index: '6',
						name: '量规管理',
						children: [
							{
								index: '6-1',
								name: '量规列表',
								path: '/home/rubrics'
							},
							{
								index: '6-2',
								name: '量规分类列表',
								path: '/home/rubriccategories'
							}
						]
					},
					{
						index: '7',
						name: '知识点管理',
						children: [
							{
								index: '7-1',
								name: '知识点列表',
								path: '/home/knowledge'
							},
							{
								index: '7-2',
								name: '知识点分类列表',
								path: '/home/knowledgecategoreis'
							}
						]
					},
					{
						index: '8',
						name: '题目管理',
						children: [
							{
								index: '8-1',
								name: '题目列表',
								path: '/home/questions'
							}
						]
					},
					{
						index: '9',
						name: '认知层级管理',
						children: [
							{
								index: '9-1',
								name: '认知层级列表',
								path: '/home/cognitivelevel'
							}
						]
					},
					{
						index: '10',
						name: '作业管理',
						children: [
							{
								index: '10-1',
								name: '作业列表',
								path: '/home/homework'
							},
							{
								index: '10-2',
								name: '套题列表',
								path: '/home/setlist'
							}
						]
					},
					{
						index: '11',
						name: '用户管理',
						children: [
							{
								index: '11-1',
								name: '租户列表',
								path: '/home/tenantslist'
							},
							{
								index: '11-2',
								name: '老师列表',
								path: '/home/teacherlist'
							},
							{
								index: '11-3',
								name: '学生列表',
								path: '/home/studentlist'
							},
							{
								index: '11-4',
								name: '角色列表',
								path: '/home/rolelist'
							}
						]
					},
					{
						index: '12',
						name: '资源管理',
						children: [
							{
								index: '12-1',
								name: '资源列表',
								path: '/home/resourceslist'
							}
						]
					},
					{
						index: '13',
						name: '课程管理',
						children: [
							{
								index: '13-1',
								name: '课程列表',
								path: '/home/curriculumlist'
							}
						]
					},
					{
						index: '14',
						name: '菜单管理',
						children: [
							{
								index: '14-1',
								name: '校级菜单列表',
								path: '/home/menulist'
							}
						]
					}
					
				]
			}
		},
		created() {
			
		},
		methods:{
			exit(){
				this.$confirm('是否退出登录？', '提示', {
					confirmButtonText: '是',
					cancelButtonText: '否',
					type: 'error',
				}).then(() => {
					sessionStorage.clear()
					this.$router.replace('/')
				})
			},
			menulistClick(path,index){
				console.log(path,index)
				if (path) {
					this.$store.commit('setdefaultid', index)
					this.$router.replace({
						path:path
					})
				}
			}
		}
	}
</script>

<style scoped>
	.home_view {
		display: flex;
		height: 100vh;
	}

	.el-aside {
		background: #FFFFFF;
	}

	.el-header {
		background: #56dbbf;
		color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 24px;
		position: relative;
	}
	.el-button{
		position: absolute;
		right: 30px;
		top: 0;
		bottom: 0;
		margin: auto;
	}

	.el-main {
		background: #f5f6f7;
		position: relative;
	}
</style>
